<template>
  <div>
    <el-container>
      <CommonTree
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="true"
        :showCheckbox="false"
        @getTreeAdd="treeMenuAdd"
        @getTreeEdit="treeMenuEdit"
        @getTreeDelete="treeNodeDel"
        @getNodeClick="treeNodeClick"
      />
      <el-container>
        <el-main>
          <head-layout
            :head-btn-options="headBtnOptions"
            :head-title="$t(`cip.plat.message.template.title.indexHeadTitle`)"
            @head-remove="headRemove"
            @head-add-tabs="headAdd"
          ></head-layout>
          <div class="headers">
            <grid-head-layout
              ref="gridHeadLayout"
              :search-columns="searchColumns"
              @grid-head-search="gridHeadSearch"
              @grid-head-empty="gridHeadEmpty"
            ></grid-head-layout>
          </div>
          <grid-layout
            ref="gridLayOut"
            :gridRowBtn="gridRowBtn"
            :tableOptions="tableOptions"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :data-total="page.total"
            @page-current-change="onLoad"
            @page-size-change="onLoad"
            @page-refresh-change="onLoad"
            @grid-row-detail-click="rowDetail"
          >
            <template #customBtn="{row}">
              <el-button
                @click="rowStop(row)"
                size="small"
                type="text"
                v-if="row.taskStatus === 1 &&row.templateMode=='001'"
              >{{ $t('cip.plat.message.template.btn.stop') }}
              </el-button>
              <el-button
                @click="rowStart(row)"
                size="small"
                type="text"
                v-if="row.taskStatus === 0&&row.templateMode=='001'&&row.status=='1'"
              >{{ $t('cip.plat.message.template.btn.start') }}
              </el-button>
              <el-button
                @click="rowEdit(row)"
                size="small"
                type="text"
                v-if="row.taskStatus === 0"
              >{{ $t('cip.cmn.btn.editBtn') }}
              </el-button>
              <el-button
                @click="rowRemove(row)"
                size="small"
                type="text"
                v-if="row.taskStatus === 0"
              >{{ $t('cip.cmn.btn.delBtn') }}
              </el-button>
              <el-button
                @click="selectBroadcast(row)"
                size="small"
                type="text"
              >{{ $t('cip.plat.app.broadcast.menu.selectBroadcast') }}
              </el-button>
            </template>
          </grid-layout>
        </el-main>
      </el-container>
    </el-container>
    <CommonDialog v-if="showTreeDialog" :modal="false" :showFullscreen="true" width="60%" :dialogTitle="$t(`cip.plat.message.template.title.indexHeadTitle`)" @cancel="showTreeDialog = false" @confirm="treeNodeSave">
      <avue-form ref="treeDialogRef" :option="treeDialogOption" v-model="form"></avue-form>
    </CommonDialog>
    <CommonDialog v-if="addrVisible" width="40%" :dialogTitle="$t('cip.desk.notice.title.settingHeadTitle')" @cancel="addrVisible = false" @confirm="submit">
      <avue-form
        :option="selOption"
        v-model="selForm"
      ></avue-form>
    </CommonDialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {remove as removeTree, saveOrUpdate as saveOrUpdateTree, tree} from '@/api/message/msgtemplateitem'
import {selectPage, remove, startCronTask, stopCronTask} from "@/api/message/msgtemplate";

import CommonTree from "@/views/components/com_tree/index"
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import TreeLayout from "@/views/components/layout/tree-layout";
import CommonDialog from "@/components/CommonDialog";
import ServerNameEnum from '@/util/ServerNameEnum';
import {
  getList as getBroadcastList,
  selectMsgBroadcast,
  submitMsgBroadcast
} from "@/api/system/broadcast";

const CONTROLLER_NAME = "msgTypeItem";

export default {
  name: "template",
  data() {
    return {
      selOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [{
          label: this.$t(`cip.plat.app.broadcast.menu.selectBroadcast`),
          prop: 'select',
          type: 'select',
          multiple: true,
          dicData: [],
          span: 24,
          labelWidth: 120,
          props: {
            label: "address",
            value: "id"
          },
        }]
      },
      selForm: {},
      addrVisible: false,
      templateId: '',
      form: {
        parentId: '',
      },
      treeReadOnlyFlag: false,
      extItemCodeFlag: true,
      tableData: [],
      treeData: [],
      drawerBox: false,
      drawerTitle: "",
      showItem: "",
      itemId: -1,
      bsItemGategory: {},
      treeLoading: false,
      tableLoading: false,
      crudData: [],
      selectionList: [],
      query: {
        msgTypeCode: "",
      },
      msgTypeCode: '', // 点击左侧树的code
      node: {},   //左侧树node
      fullscreen: false,
      showTreeDialog: false,
      searchTitle: 'msgTypeName',
      defaultProps: {
        label: "msgTypeName",
        value: "key",
        key: "msgTypeCode"
      },
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      searchColumns: [
        {
          label: "",
          prop: "templateCode",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.plat.message.template.field.templateCode`)
        },
        {
          label: "",
          prop: "templateName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.plat.message.template.field.templateName`),
        },
        {
          label: this.$t(`cip.plat.message.template.field.statusStr`),
          placeholder: this.$t(`cip.plat.message.template.field.statusStr`),
          labelWidth: 130,
          span: 4,
          prop: "status",
          type: 'select',
          dataType: 'number',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=EnableOrNot",
        },
      ],
      gridRowBtn: [],
      treeOptions() {
        return {
          title: this.$t('cip.qm.business.md.extItemCate.title.title'),
          defaultExpandAll: true,
          nodeKey: 'id',
          size: 'mini',
          menu: true,
          lazy: false,
          dialogType: 'drawer',
          addBtn: false,
          editBtn: false,
          delBtn: false,
          props: {
            labelText: this.$t("cip.qm.business.md.extItemCate.title.title"),
            id: "id",
            label: 'msgTypeName',
            value: 'id',
            children: 'children'
          },
        };
      },
      treeDialogOption: {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: this.$t("cip.plat.message.template.field.parentName"),
            prop: "parentId",
            type: "tree",
            props: {
              label: 'msgTypeName',
              value: 'id',
              key: 'parentId',
            },
            placeholder: this.$t("cip.plat.message.template.field.parentName"),
            dicUrl: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/selectTree`,
          },
          {
            label: this.$t("cip.plat.message.template.field.status"),
            prop: "isEnable",
            type: 'select',
            value: 'y',
            dataType: 'string',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.message.template.field.status'),
              trigger: "blur"
            }],
            placeholder: this.$t("cip.plat.message.template.field.status"),
          },
          {
            label: this.$t("cip.plat.message.template.field.msgTypeEncoding"),
            prop: "msgTypeCode",
            maxlength: 20,
            readonly: this.treeReadOnlyFlag,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t("cip.plat.message.template.field.msgTypeEncoding"),
                trigger: "blur",
              },
              {
                pattern: /^[^\s]+(\s+[^\s]+)*$/,
                message: this.$t('cip.cmn.rule.deleteWarning'),
              }
            ],
          },
          {
            label: this.$t("cip.plat.message.template.field.msgTypeName"),
            prop: "msgTypeName",
            maxlength: 50,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t("cip.plat.message.template.field.msgTypeName"),
                trigger: "blur",
              },
              {
                pattern: /^[^\s]+(\s+[^\s]+)*$/,
                message: this.$t('cip.cmn.rule.deleteWarning'),
              }
            ],
          },
          {
            label: this.$t("cip.plat.message.template.field.remark"),
            prop: "remark",
            type: "textarea",
            overHidden: "15",
            span: 24,
            minRows: 2,
            maxRows: 2,
            maxlength: 200,
            showWordLimit: true,
          },
        ]
      },
      tableOptions: {
        linklabel: "templateCode",
        menuWidth: 180,
        column: [
          {
            label: this.$t(`cip.plat.message.template.field.templateCode`),
            prop: "templateCode",
            search: true,
          },
          {
            label: this.$t(`cip.plat.message.template.field.templateName`),
            prop: "templateName",
            search: true,
          },
          {
            label: this.$t(`cip.plat.message.template.field.templateType`),
            labelWidth: 130,
            clearable: true,
            dicUrl: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/selectTree`,
            prop: "templateType",
            type: 'tree',
            span: 8,
            align: "center",
            props: {
              label: 'msgTypeName',
              value: 'msgTypeCode',
              key: 'msgTypeCode',
            },
          },
          {
            label: this.$t(`cip.plat.message.template.field.templateMode`),
            prop: "templateMode",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=send_model`,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            align: "center"
          },
          {
            label: this.$t(`cip.plat.message.template.field.statusStr`),
            prop: "status",
            align: "center",
            type: 'select',
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=EnableOrNot",
          },
          /*{
            label: this.$t(`sinoma.message.template.taskStatuStr`),
            prop: "taskStatuStr",
            align: "center"
          },*/
          {
            label: this.$t(`cip.plat.message.template.field.createTime`),
            prop: 'createTime',
            align: "center",
            overHidden: true
          },
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      if (this.permission.template_add) {
        result.push({
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        });
      }
      if (this.permission.template_delete) {
        result.push({
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        });
      }
      return result
    }
  },
  components: {
    GridLayout,
    HeadLayout,
    TreeLayout,
    CommonTree,
    CommonDialog
  },
  mounted() {
    this.treeLoading = true;
    tree()
      .then((result) => {
        this.treeData = result.data.data;
        this.treeLoading = false;
      })
      .catch((err) => {
        this.treeLoading = false;
      });
    this.onLoad(this.page, {});
    this.getBroadcastList()
  },
  methods: {
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    // 左侧树新增
    treeMenuAdd() {
      this.form = {};
      this.form.parentId = this.node.id;
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
    },

    // 左侧树编辑
    treeMenuEdit(data) {
      this.treeReadOnlyFlag = true;
      this.fullscreen = false;
      this.showTreeDialog = true;
      this.form = data;
    },

    initTree() {
      tree({parentId: 0}).then(res => {
        const {data} = res.data;
        this.treeData = data;
      });
    },
    // 新增左侧树节点
    treeNodeSave() {
      this.$refs.treeDialogRef.validate((valid) => {
        if (valid) {
          saveOrUpdateTree(this.form).then(res => {
            const {msg, data} = res.data;
            if (res.data.code == 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(msg);
            }
            this.initTree();
            this.showTreeDialog = false;
          }).catch(() => {

          })
        }
      })
    },
    // 删除左侧树节点
    treeNodeDel(data, done) {
      if (data.children.length > 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.hasChildrenWarning'))
        return
      }

      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      }).then(() => {
        removeTree(data.id).then(res => {
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
          this.initTree()
          done()
        })
      }).catch(() => {
      });
    },

    // 点击左侧树节点
    treeNodeClick(node) {
      this.node = node;
      this.msgTypeCode = node.id;
      this.page.total = 0;
      this.onLoad(this.page, {});
    },

    headRemove() {
      let selectionList = this.$refs.gridLayOut.selectionList
      let ids = selectionList.filter(e => e.id).map(e => e.id)
      if (selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          if (ids.length === 0) {
            //只选中了空数据
            let index = 0;
            selectionList.filter(e => !e.id).forEach(e => {
              this.$refs.gridLayOut.tableData.splice(e.$index - index, 1);
              index++
            })
            this.$refs.gridLayOut.selectionClear()
            return;
          }
          return remove(this.$func.join(ids))
            .then(() => {
              this.onLoad(this.page, {});

              this.$message({
                type: "success",
                message: this.$t('cip.cmn.msg.success.operateSuccess')
              });
              this.$refs.gridLayOut.selectionClear()
            });
        }).catch(() => {
      })
    },
    rowStart(row) {
      this.$confirm(this.$t('cip.plat.message.template.msg.start'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return startCronTask(row.id);
        })
        .then(() => {
          this.onLoad(this.page, {});
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    rowStop(row) {
      this.$confirm(this.$t('cip.plat.message.template.msg.stop'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return stopCronTask(row.id);
        })
        .then(() => {
          this.onLoad(this.page, {});
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page, {});
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },

    rowDetail(row) {
      this.$router.push({
        path: `/message/template/view`,
        query: {
          data: row,
          type: 'view',
          pageType: 'detail'
        }
      })
    },

    rowEdit(row) {
      this.$router.push({
        path: `/message/template/edit`,
        query: {
          data: row,
          type: 'edit',
          pageType: 'edit'
        }
      })
    },

    // 新增
    headAdd() {
      if (!this.msgTypeCode) {
        this.$message.warning(this.$t('cip.plat.message.template.msg.select'));
        return;
      }
      this.$router.push({
        path: `/message/template/add`,
        query: {
          pageType: 'add',
          type: 'add',
          node: this.node
        }
      })
    },

    selectBroadcast(row) {
      this.addrVisible = true
      this.selForm.select = []
      this.templateId = row.id
      this.selectMsgBroadcast(this.templateId)
    },
    selectMsgBroadcast(id) {
      selectMsgBroadcast(id).then(res => {
        this.selForm.select = res.data.data;
        console.log("this.selForm.select", this.selForm.select)
      })
    },
    submit() {
      let ids = [];
      this.selForm.select.forEach(ele => {
        ids.push(ele);
      });
      submitMsgBroadcast(ids.join(","), this.templateId).then(res => {
        this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
        this.addrVisible = false;
      })
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.msgTypeCode = '',
        this.onLoad(this.page, query)
    },

    close() {
      this.drawerBox = false;
      this.page.currentPage = 1;
      this.onLoad(this.page, {});
    },
    saveHandler() {
      this.drawerBox = true;
      this.drawerTitle = "添加物料";
      this.showItem = "add";
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      if (this.msgTypeCode) {
        params.templateType = this.msgTypeCode;
      }
      selectPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      )
        .then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
          this.selectionClear();
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    searchChange() {
      const extItemCatId = this.query.extItemCatId;
      this.query = {
        keyword: this.keyword,
      };
      this.query.extItemCatId = extItemCatId;
      this.page.currentPage = 1;
      this.onLoad(this.page, {});
    },
    searchReset() {
      const extItemCatId = this.query.extItemCatId;
      this.query = {};
      this.query.extItemCatId = extItemCatId;
      this.page.currentPage = 1;
      this.onLoad(this.page, {});
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    getBroadcastList() {
      getBroadcastList().then(res => {
        const data = res.data.data;
        console.log("data", data)
        this.findObject(this.selOption.column, 'select').dicData = data
      });
    }
  },
};
</script>

